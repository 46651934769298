export default [
  {
    title: 'NO',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Distributor SI',
    dataIndex: 'kode_si',
    // width: 100,
  },
  {
    title: 'Kode Distributor SBI',
    dataIndex: 'kode_sbi',
    slots: { customRender: 'nilai' },
    width: 200,
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distributor',
    // width: 160,
  },
   {
    title: 'Kemampuan Bongkar Gudang',
    dataIndex: 'kapasitas_bongkar',
    slots: { customRender: 'nilai' },
    // width: 200,
  },
   {
    title: 'SO Clean & Clear (Zak)',
    dataIndex: 'so_hari_ini',
    slots: { customRender: 'nilai' },
    // width: 100,
  },
  {
    title: 'Ketahanan SO',
    dataIndex: 'ketahanan_so',
    customRender: ({ text, index }) => {
      if (parseInt(text) < 7 || !text) {
        return {
          children: text ? Math.round(text) : 0,
          props: {
            class: 'text-custom-red',
          },
        };
      }
      return text ? Math.round(text) : 0;
    },
    // width: 150,
  },
 {
    title: 'Volume Stok Gudang',
    dataIndex: 'volume_stock_gudang',
    slots: { customRender: 'nilai' },
    // width: 120,
  },
   {
    title: 'Level Stok Gudang',
    dataIndex: 'level_stock',
    customRender: ({ text, index }) => {
      if (parseInt(text) >= 100) {
        return {
          children: text ? Math.round(text) + '%' : 0,
          props: {
            class: 'text-custom-red',
          },
        };
      }
      else if (parseInt(text) < 100 && parseInt(text) >= 80 ) {
        return {
          children: text ? Math.round(text) + '%' : 0,
          props: {
            class: 'text-custom-orange',
          },
        };
      }
      return {
        children: text ? Math.round(text) + '%' : 0,
        props: {
          class: 'text-custom-dark',
        },
      };
    },
    // width: 120,
  },
   {
    title: 'Rata - rata Penjualan (ZAK)',
    dataIndex: 'penjualan_avg',
    slots: { customRender: 'pembulatan' },
    // width: 120,
  },
   {
    title: 'Ketahanan Stok (Hari)',
    dataIndex: 'ketahanan_stock',
    customRender: ({ text, index }) => {
      if (parseInt(text) < 7 || !text) {
        return {
          children: text ? Math.round(text) : 0,
          props: {
            class: 'text-custom-red',
          },
        };
      }
      return text ? Math.round(text) : 0;
    },
  },
   {
    title: 'Ship Out [Pengiriman Pabrik H-1](ZAK)',
    dataIndex: 'ship_out',
    slots: { customRender: 'nilai' },
  },
]
